

.TenentTenancy-header {
    font-size: 40px;
    font-weight: 400;
    font-family: inherit;
}
.TenentTenancy-header2 {
    font-size: 26px;
    font-weight: 400;
    font-family: inherit;
}

.TenentTenancy-Box {
    border: 1px solid rgb(192, 192, 192);
    border-radius: 10px;
}

.TenentTenancy-Box-grid {
    display: grid;
    grid-template-columns: 17% 80%;
    column-gap: 2%;
    padding-top: 10px;
    font-size: 16px;
    color: rgb(65, 65, 65);
}

.TenentTenancy-Box-grid1 {
    text-align: right;
    font-size: 18px;
    font-weight: 500 !important;
    margin-top: 0px !important;
}

.TenentTenancy-text-link {
    text-decoration: none;
    color: #337ab7;
}

.TenentTenancy-text-link:hover {
    text-decoration: underline;
}

.TenentTenancy-input-group-img {
    width: 16px;
    height: auto;
    display: block;
}

.phone-icon {
    font-size: 20px;
}



@media screen and (max-width: 800px) {
    .TenentTenancy-header {
        font-size: 34px;
    }
    .TenentTenancy-header2 {
        font-size: 20px;
    }
    .TenentTenancy-Box-grid {
        grid-template-columns: auto;
    }
    .TenentTenancy-Box-grid1 {
        font-size: 20px;
        text-align: left;
        margin-top: 0px !important;
        margin-bottom: 15px !important;
        font-weight: 500 ;
        color: black;
    }
    .TenentTenancy-Box-grid2 {
        font-size: 16px;
    }
}

@media screen and (max-width: 550px) {
    .TenentTenancy-header {
        font-size: 30px;
    }
    .TenentTenancy-header2 {
        font-size: 16px;
    }
    .input-group-text2 {
        height: 39px;
        margin-top: 10px;
    }
}